
import { Component, Vue } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'

@Component
export default class Renewal extends Vue {
  mounted (): void {
    var branchId = this.$route.params.id
    if (!branchId) {
      console.log('Failed to get branchid')
    }
    Services.API.Payment.GetRenewalLink(branchId)
      .then((url) => {
        window.open(url.Url, '_self')
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        // finally
      })
  }
}
